import React, { Fragment } from 'react'
import Layout from '../../layout'
import Accordion from '../../components/BuildingAppsCrashCourse/Accordion'
import ConsoleBanner from '../../components/BuildingAppsCrashCourse/ConsoleBanner'
import LessonSection from '../../components/BuildingAppsCrashCourse/LessonSection'
import NextLesson from '../../components/BuildingAppsCrashCourse/NextLesson'
import LessonResources from '../../components/BuildingAppsCrashCourse/LessonResources'
import LessonHeader from '../../components/BuildingAppsCrashCourse/LessonHeader'
import YoutubeVideo from '../../components/BuildingAppsCrashCourse/YoutubeVideo'
import { transcript } from '../../components/BuildingAppsCrashCourse/transcript'
import { SEO } from '../../components'
import BuildingAppsCrashCourseOgImage from '../../assets/images/building-apps-crash-course-og-image.png'

import './building-apps-crash-course.scss'

const PlanAppDevelopment = () => (
  <Layout className="bacc_layout-styles" isCourseSite>
    <SEO
      title="Plan out the app development"
      description="Master your planning and take your developer skills to the next level. Learn the foundational steps for creating a successful app in our crash course!"
      image={BuildingAppsCrashCourseOgImage}
    />
    <div className="bacc_main-wrapper">
      <LessonHeader
        title="1. Plan out the app development"
        summary={
          <Fragment>
            <p>
              Welcome to the The Practical Guide to Building LiveChat Apps! In
              our first video, you’ll learn how to approach one of the most
              essential parts of app development — planning your work. We’ll
              also prepare the base for the training application we’ll be
              working on throughout the next episodes.
            </p>
            <p>Let’s get you started!</p>
          </Fragment>
        }
      />
      <YoutubeVideo id="ncKVOy2OSbw" eventLabel="plan app development" />
      <section className="bacc_main-section">
        <h3 className="u-font-bold bacc_section-title">Video summary</h3>
        <div>
          {transcript.planAppDevelopment.map((item, index) => (
            <Accordion
              key={index}
              num={index}
              title={item.title}
              content={item.content}
            />
          ))}
        </div>
      </section>
      <LessonResources
        transcriptSlug="building-apps-crash-course-plan-app-development.pdf"
        isRepo
        pageName="Plan out the app development"
      />
      <NextLesson
        title="2. LiveChat APIs in practice"
        description="You'll implement authorization, start communicating with LiveChat APIs, and polish your app interface."
        slug="/building-apps-crash-course/livechat-apis"
      />
      <LessonSection />
    </div>
    <ConsoleBanner />
  </Layout>
)

export default PlanAppDevelopment
